/* Sanhoua Family Website - Main Styles */

:root {
  /* Ivory Coast flag colors */
  --orange: #FF7900;
  --white: #FFFFFF;
  --green: #009E60;
  
  /* Additional colors for UI */
  --light-gray: #F5F5F5;
  --medium-gray: #E0E0E0;
  --dark-gray: #333333;
  --text-color: #212121;
  --link-color: #006E44;
  --error-color: #D32F2F;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', 'Segoe UI', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--light-gray);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Header */
.header {
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--orange);
}

.logo span {
  color: var(--green);
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  margin-left: 1.5rem;
}

.nav-links a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: var(--orange);
}

.language-switcher {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}

.language-switcher button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
  color: var(--text-color);
}

.language-switcher button.active {
  color: var(--orange);
  font-weight: 700;
}

/* Hero Section */
.hero {
  background: linear-gradient(135deg, var(--orange) 0%, var(--green) 100%);
  color: var(--white);
  padding: 4rem 0;
  text-align: center;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto 2rem;
}

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: var(--white);
  color: var(--orange);
}

.btn-primary:hover {
  background-color: var(--light-gray);
}

.btn-secondary {
  background-color: transparent;
  color: var(--white);
  border: 2px solid var(--white);
}

.btn-secondary:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Main Content */
.main {
  padding: 3rem 0;
}

.section {
  margin-bottom: 3rem;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--green);
  position: relative;
  padding-bottom: 0.5rem;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: var(--orange);
}

/* Cards */
.card {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.card-header {
  padding: 1.5rem;
  background-color: var(--green);
  color: var(--white);
}

.card-body {
  padding: 1.5rem;
}

.card-footer {
  padding: 1rem 1.5rem;
  background-color: var(--light-gray);
  border-top: 1px solid var(--medium-gray);
}

/* Grid */
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

/* Family Tree */
.family-tree {
  background-color: var(--white);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.tree-node {
  padding: 1rem;
  border: 2px solid var(--green);
  border-radius: 8px;
  margin-bottom: 1rem;
  position: relative;
}

.tree-node::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 2px;
  height: 20px;
  background-color: var(--green);
}

.tree-node:last-child::before {
  display: none;
}

.tree-node-children {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

/* Forms */
.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--medium-gray);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-control:focus {
  outline: none;
  border-color: var(--green);
}

/* Footer */
.footer {
  background-color: var(--dark-gray);
  color: var(--white);
  padding: 3rem 0;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin-bottom: 1.5rem;
}

.footer-section h3 {
  margin-bottom: 1rem;
  color: var(--orange);
}

.footer-links {
  list-style: none;
}

.footer-links li {
  margin-bottom: 0.5rem;
}

.footer-links a {
  color: var(--white);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: var(--orange);
}

.footer-bottom {
  text-align: center;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Responsive */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .nav-links {
    margin-top: 1rem;
    flex-direction: column;
  }
  
  .nav-links li {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
  
  .language-switcher {
    margin-left: 0;
    margin-top: 1rem;
  }
  
  .hero h1 {
    font-size: 2rem;
  }
  
  .hero p {
    font-size: 1rem;
  }
}

/* WhatsApp Integration */
.whatsapp-login {
  display: flex;
  align-items: center;
  background-color: #25D366;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
}

.whatsapp-login i {
  margin-right: 0.5rem;
}

/* Contribution Tracking */
.contribution-status {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-completed {
  background-color: var(--green);
  color: var(--white);
}

.status-pending {
  background-color: var(--orange);
  color: var(--white);
}

.status-overdue {
  background-color: var(--error-color);
  color: var(--white);
}
